import myLogo from "../images/logo.png";

const Logo = () => {
  return (
    <div className="alignleft">
      <img src={myLogo} alt="AS" width="100vw" height="100vh" />
    </div>
  );
};

export default Logo;
