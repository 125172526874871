import Logo from "./Logo";

const Menu = () => {
  return (
    <div id="menu-outer">
      <Logo />
    </div>
  );
};

export default Menu;
